<template>
  <el-main>
    <div class="flexBox">
      <SmallCard :info="fixedSetMeal" @clickFn="$router.push('/extension/fixedSetMeal')"></SmallCard>
      <SmallCard :info="collocationSetMeal" @clickFn="$router.push('/extension/collocationSetMeal')"></SmallCard>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="套餐名称：">
        <el-input v-model="ruleForm.package_name" size="small" placeholder="请输入套餐名称"></el-input>
      </el-form-item>
      <el-form-item label="套餐类型：">
        <el-select size="small" v-model="ruleForm.type" placeholder="请选择" class="classify_select">
          <el-option v-for="item in setMealType" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否上架：">
        <el-select size="small" v-model="ruleForm.status">
          <el-option v-for="item in setMealStatus" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getSetMealList(1)">搜索</el-button>
        <el-button size="small">导出</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 渲染表格 -->
    <el-table :data="setMealList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="套餐主图" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 60px; height: 60px" :src="row.cover_picture"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="package_name" label="套餐名称" align="center"></el-table-column>
      <el-table-column label="套餐类型" align="center">
        <template v-slot="{ row }">
          {{ row.type === 1 ? '固定套餐' : '搭配套餐' }}
        </template>
      </el-table-column>
      <el-table-column label="上/下架" align="center">
        <template v-slot="{ row }">
          <el-button @click="changeStatus(row)" :type="!row.status ? 'primary' : ''" size="small">{{ row.status ? '下架' : '上架' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="toEdit(row)">编辑</el-button>
          <el-button type="text" @click="deleteSetMeal(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import SmallCard from '@/components/smallCardBox.vue';
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
export default {
  components: {
    SmallCard,
    Paging,
  },
  data() {
    return {
      getDateformat,
      fixedSetMeal: {
        img: require('@/assets/smallCardImgs/1.png'),
        title: '固定套餐',
        desc: '用户需要购买套餐内所有商品',
        btnTxt: '立即创建',
      },
      collocationSetMeal: {
        img: require('@/assets/smallCardImgs/1.png'),
        title: '搭配套餐',
        desc: '套餐主商品必选，其他搭配任选',
        btnTxt: '立即创建',
      },
      setMealType: [
        { name: '全部套餐', id: 0 },
        { name: '固定套餐', id: 1 },
        { name: '搭配套餐', id: 2 },
      ],
      setMealStatus: [
        { name: '全部', id: 0 },
        { name: '下架', id: 1 },
        { name: '上架', id: 2 },
      ],
      ruleForm: {
        package_name: '',
        type: 0,
        status: 0,
      },
      page: 1,
      rows: 10,
      total: 0,
      setMealList: [],
    };
  },
  created() {
    this.getSetMealList();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getSetMealList();
    },
    cancelSearch() {
      this.ruleForm = {
        package_name: '',
        type: 0,
        status: 0,
      };
      this.getSetMealList(1);
    },
    getSetMealList(style) {
      if (style) this.page = 1;
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.ruleForm.package_name) {
        obj.package_name = this.ruleForm.package_name;
      }
      if (this.ruleForm.type) {
        obj.type = this.ruleForm.type;
      }
      if (this.ruleForm.status) {
        obj.status = this.ruleForm.status;
      }
      this.$axios.post(this.$api.beauty.setMeal.setMealList, obj).then(res => {
        if (res.code === 0) {
          this.setMealList = res.result.list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeStatus(data) {
      let status = data.status ? 0 : 1;
      let str = data.status ? '下架成功' : '上架成功';
      this.$axios
        .post(this.$api.beauty.setMeal.editStatus, {
          id: data.id,
          status,
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success(str);
            data.status = status;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    deleteSetMeal(data) {
      this.$confirm(`此操作将永久删除该套餐！！！`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          return await this.$axios.post(this.$api.beauty.setMeal.delSetMeal, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getSetMealList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    toEdit(data) {
      let url = data.type === 1 ? '/extension/fixedSetMeal' : '/extension/collocationSetMeal';
      url = url + '?id=' + data.id;
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .flexBox {
    display: flex;
    align-items: center;
  }
}
</style>
